<template>
  <div class="content">
    <nav-bar header-title="我的组队记录" :show-back="true" :header-back="back" ref="head" :placeholder="false" />
    <div :style="{ paddingTop: stickyHeight + 'px' }">
      <van-sticky :offset-top="stickyHeight">
        <div class="tab">
          <span @click="mShow = true">{{ mText }}<van-icon name="play" class="icon" /></span>
        </div>
      </van-sticky>
      <div v-for="i in list" class="item">
        <p>{{ i.operationTime }} 你{{ operationType[i.operationType] }}</p>
        <div class="detail">
          <img class="" :src="i.teamLogo" alt="">
          <div class="team">
            <p class="f28 fw6 ellipsis-1">{{ i.teamName }}</p>
            <template v-if="i.operationType == 2">
              <p class="tips c1" v-if="i.joinStatus == 0">待队长通过… <van-count-down :time="i.waitFinishSecond * 1000"
                  format="HH:mm:ss" />后失效</p>
              <p class="tips" :class="i.joinStatus == 1 ? 'c3' : 'c2'" v-else>{{ i.joinStatus == 1 ? '队长通过，你已加入该战队' :
                i.failMsg }}</p>
            </template>
          </div>
        </div>
      </div>
      <div v-if="list.length == 0" class="empty">
        <img :src="baseImgUrl + 'empty.png'" alt="">
        <p>暂无组队记录</p>
      </div>
    </div>
    <van-popup v-model="mShow" safe-area-inset-bottom position="bottom" class="pop-radius">
      <van-picker title="月份选择" show-toolbar :columns="mList" @confirm="monthConfirm" @cancel="mShow = false"
        :default-index='defaultIndex' />
    </van-popup>
  </div>
</template>

<script>
import navBar from '@/components/nav-bar/nav-bar'
import { getLastMonth, getMonthStartAndEnd, getMonthRange } from '@/lib/utils';
import userMixin from '@/mixin/userMixin';

export default {
  components: {
    navBar
  },
  mixins: [userMixin],
  data() {
    return {
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/teamWarband-202308/',
      stickyHeight: 0,
      mShow: false,
      mList: [],
      mText: '',
      mValue: '',
      defaultIndex: 0,
      lessonNum: 0,
      list: [],
      cityId2: '',
      operationType: {
        1: '创建战队',
        2: '申请加入战队',
        3: '退出战队',
        4: '解散战队',
      },
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.stickyHeight = this.$refs.head.$el.offsetHeight
    }, 100)

  },
  async created() {
    await this.$getAllInfo(['userId'])
    this.mList = getLastMonth(getMonthRange(new Date('2024-09-01'), new Date()) + 1)
    this.mValue = this.$route.query.mValue
    this.cityId2 = this.$route.query.cityId
    this.mList.forEach((item, index) => {
      if (item.value === this.mValue) {
        this.mText = item.text
        this.defaultIndex = index
      }
    })
    this.getList()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    monthConfirm(m) {
      this.mText = m.text
      this.mValue = m.value
      this.mShow = false
      this.getList()
    },
    getList() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.$axios.post(this.baseURLApp + '/schedule/teamActivity/queryTeamOperationRecordList', {
        userId: this.userId,
        cityId: this.cityId2,
        effectMonth: this.mValue
      }).then((res) => {
        this.list = res.data
      })
    },
  }
}
</script>

<style lang="less" scoped>
.content {
  background: #F5F5F5;
  min-height: 100vh;
  padding-bottom: 30px;

  .tab {
    background-color: #F5F5F5;
    padding: 28px 38px;
    font-size: 28px;
    box-sizing: border-box;
    display: flex;
    justify-content: right;

    .icon {
      transform: rotate(90deg);
      margin-top: -1px;
    }
  }

  .empty {
    text-align: center;
    padding-top: 250px;

    img {
      width: 320px;
      height: 320px;
    }

    p {
      color: #6C727A;
      font-size: 28px;
      padding-top: 10px;
    }
  }

  .item {
    width: 686px;
    height: 202px;
    margin: 0 auto 24px;
    padding: 28px 32px 28px 28px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    font-size: 22px;
    color: #6C727A;

    .detail {
      margin-top: 24px;
      display: flex;

      img {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        margin-right: 24px;
      }

      .team {
        color: #242831;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: center;

        .tips {
          margin-top: 10px;
          font-size: 22px;

          >div {
            display: inline;
            font-size: 22px;
            color: #F03C18;
            margin-right: 5px;
          }
        }

        .c1 {
          color: #F03C18;
        }

        .c2 {
          color: #9AA1A9;
        }

        .c3 {
          color: #267DFF;
        }
      }
    }
  }
}
</style>